import React from 'react';
import Layout from 'components/Layout';
import { Link, navigate } from 'gatsby';
import Helmet from 'react-helmet';
import Header from 'components/Headers/Header';
import { Fluid, Offset } from 'components/Containers';

class PageNotFound extends React.Component {
  componentDidMount () {
    navigate(`/products`);
  }
  render () {
    const headerProps = {
      height: `70vh`,
      bgColor: `gray-cold-100`,
      ui: 90
    };
    return (
      <Layout>
        <Fluid>
          <Helmet title={`${`Not Found`}`}>
            <meta name="robots" content="noindex" />
          </Helmet>
          <Header {... headerProps}>
            <Offset>
              <h1 className={`fs-60em lh-10 ls--10em mb-3em fw-300`}>Page not found</h1>
              <p className="lead fs-20em">Sorry, we couldn't find the page you were looking for.</p>
              <p className={`pb-20em`}>Perhaps you would like to go to the <Link style={{ fontWeight: `600` }} to={`/`}>Apantac's homepage</Link>, <Link style={{ fontWeight: `600` }} to={`/search/`}>Search our website</Link> or <Link style={{ fontWeight: `600` }} to={`/company/contact/`}>Contact Us</Link>.</p>
            </Offset>
          </Header>
        </Fluid>
      </Layout>
    );
  }
}

export default PageNotFound;
